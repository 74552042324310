<template>
  <div class="modal modal-center" id="uploadItems">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-body">
        <div class="modal-title">Rate lock agreement</div>
        <file-field-new :multiple="false" :files="rateLockFiles" class="mt-1" field-id="rate-lock-agreement" @change="submitRateLocks"/>
        <ul class="list-group mt-2" v-if="rateLockAgreements.length">
          <li v-for="doc in rateLockAgreements" :key="doc.id" class="list-group-item d-flex justify-content-between align-items-center">
            {{ doc.clientFilename }}
            <div class="list-group-item-icons d-flex">
                <span class="badge cursor-pointer btn btn-control" @click="openFilePreview('loan_referral', doc.id, doc.clientFilename)">
                  <img src="@/assets/icons/icon-preview.svg" alt="preview">
                </span>
              <span class="badge cursor-pointer btn btn-control" @click="deleteRefDoc(doc.id, 'rateLock')">
                    <img src="@/assets/icons/icon-delete.svg" alt="delete">
                  </span>
            </div>
          </li>
        </ul>

        <div class="modal-title mt-5">Audio recording of call</div>
        <file-field-new :multiple="false" :files="audioRecordFiles" class="mt-1" field-id="audio-recording-of-call" @change="submitAudioRecords"/>
        <ul class="list-group mt-2" v-if="audioRecordsOfCall.length">
          <li v-for="doc in audioRecordsOfCall" :key="doc.id" class="list-group-item d-flex justify-content-between align-items-center">
            {{ doc.clientFilename }}
            <div class="list-group-item-icons d-flex">
                <span class="badge cursor-pointer btn btn-control" @click="openFilePreview('loan_referral', doc.id, doc.clientFilename)">
                  <img src="@/assets/icons/icon-preview.svg" alt="preview">
                </span>
              <span class="badge cursor-pointer btn btn-control" @click="deleteRefDoc(doc.id, 'audio')">
                    <img src="@/assets/icons/icon-delete.svg" alt="delete">
                  </span>
            </div>
          </li>
        </ul>

        <div class="d-flex mt-4">
          <button class="btn bg-green" @click="saveForm">
            <div v-if="ui.saveLoading === true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
            </div>
            <div v-if="ui.saveLoading === false">Upload</div>
          </button>
          <button class="btn btn-cancel ms-2 px-5" @click="$emit('close')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileFieldNew from '../../components/FileFieldNew.vue';
export default {
  name: "UploadItemsModal",
  components: { FileFieldNew },
  props: {
    loanId: { required: true, type: Number },
  },
  data() {
    return {
      ui: {
        saveLoading: false,
      },
      rateLockAgreements: null,
      audioRecordsOfCall: null,
      rateLockFiles: [],
      rateLockFile: null,
      audioRecordFiles: [],
      audioRecordFile: null,
    };
  },
  methods: {
    submitRateLocks(files) {
      this.rateLockFiles = files;
    },
    submitAudioRecords(files) {
      this.audioRecordFiles = files;
    },
    getReferralDocs() {
      this.$http.get(`/api/v1/loans/referral-docs/get/${this.loanId}`)
        .then((response) => {
          this.rateLockAgreements = response.data.rateLockAgreements
          this.audioRecordsOfCall = response.data.audioRecordsOfCall
        })
        .catch()
    },
    saveForm() {
      this.ui.saveLoading = true;
      let formData = new FormData();
      formData.append("rateLockAttachment", this.rateLockFile);
      formData.append("audioRecordOfCall", this.audioRecordFile);

      this.$http
        .post(`/api/v1/loans/referral-docs/upload/${this.loanId}`, formData)
        .then(() => {
          this.pushAlert('success', 'Docs uploaded successfully.')
          this.$emit("close");
        })
        .catch(() => {
          this.pushAlert('error', 'Something went wrong, refresh page and try again, please.')
        })
        .finally(() => {
          this.ui.saveLoading = false;
          this.file = null;
        });
    },
    deleteRefDoc(id, type) {
      if (confirm("Are you sure?")) {
        this.$http.get(`/api/v1/loans/referral-docs/delete/${id}`)
        if (type === 'audio') {
          this.rateLockAgreements = this.rateLockAgreements.filter((doc) => doc.id !== id)
        } else {
          this.audioRecordsOfCall = this.audioRecordsOfCall.filter((doc) => doc.id !== id)
        }
      }
    }
  },
  watch: {
    rateLockFiles() {
      this.rateLockFile = this.rateLockFiles[0];
    },
    audioRecordFiles() {
      this.audioRecordFile = this.audioRecordFiles[0];
    }
  },
  mounted() {
    this.getReferralDocs()
  }
};
</script>

<style lang="scss">
#uploadItems {
  .modal-block {
    height: 400px;
    max-width: 500px;
    width: 100%;
    border: 0.0625rem solid rgba(239, 239, 239, 1);
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.07);
    margin: 100px auto;
    overflow: hidden;
  }
  .modal-body {
    padding: 30px;
    .modal-title {
      color: rgba(0, 0, 0, 1);
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1rem;
    }
  }
}
</style>
